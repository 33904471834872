import { Fragment } from 'react';
import { Spinner } from '@chakra-ui/react';
import TextBubble from '../../atoms/TextBubble';
import ChatInputForm from '../ChatInputForm';
import {
  StyledChatWrapper,
  StyledHistoryFetchTrigger,
  StyledInputForm,
  StyledSpinnerWrapper,
  StyledTextBubbleSpacer,
  StyledTextBubbleWrapper,
  StyledTextButtonWrapper,
} from './styled';
import LinkBubble from '../../atoms/LinkBubble';
import TextButton from '../../atoms/Button/TextButton';
import { RefreshIcon } from '../../../assets/svg';

export interface ChatItem {
  id: string;
  text: string;
  timeStamp: string;
  direction: 'left' | 'right';
  imageUrl?: string;
  linkUrl?: string;
}
export interface ChatGroupProps {
  chatList: ChatItem[];
  onSubmit: (text: string) => void;
  isDisabled: boolean;
  isChatbotLeave: boolean;
  reCallChatbot: () => void;
  isFinal: boolean;
  isLoading: boolean;
}

function ChatGroup({
  chatList,
  onSubmit,
  isDisabled,
  isChatbotLeave,
  reCallChatbot,
  isFinal,
  isLoading,
  ...rest
}: ChatGroupProps) {
  return (
    <StyledChatWrapper>
      <StyledTextBubbleWrapper id="textBubbleWrapper">
        <StyledHistoryFetchTrigger id="historyFetchTrigger" />
        <StyledTextBubbleSpacer />
        {chatList.map(
          ({ id, text, timeStamp, direction, imageUrl = '', linkUrl = '' }) => (
            <Fragment key={id}>
              {text.length > 1 && (
                <TextBubble
                  isFinal={isFinal}
                  key={id + text}
                  direction={direction}
                  timeStamp={timeStamp}
                  {...rest}
                >
                  {text}
                </TextBubble>
              )}
              {linkUrl && imageUrl && (
                <LinkBubble
                  imageUrl={imageUrl}
                  linkUrl={linkUrl}
                  key={linkUrl + id}
                  timeStamp={timeStamp}
                  {...rest}
                >
                  {linkUrl}
                </LinkBubble>
              )}
            </Fragment>
          )
        )}
        {isChatbotLeave && (
          <StyledTextButtonWrapper>
            <TextButton
              onClick={reCallChatbot}
              leftIcon={<RefreshIcon />}
              colorScheme="white"
              contentFit
              color="black"
            >
              Refresh
            </TextButton>
          </StyledTextButtonWrapper>
        )}
        {isChatbotLeave && <TextButton onClick={reCallChatbot} />}
        {isLoading && (
          <StyledSpinnerWrapper>
            <Spinner
              className="spinner"
              color="#45d6df"
              size="md"
              thickness="3px"
            />
          </StyledSpinnerWrapper>
        )}
      </StyledTextBubbleWrapper>
      <StyledInputForm>
        <ChatInputForm onSubmit={onSubmit} disabled={isDisabled} />
      </StyledInputForm>
    </StyledChatWrapper>
  );
}
export default ChatGroup;
