import styled from '@emotion/styled';

const StyledChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const StyledHistoryFetchTrigger = styled.li`
  padding: 4px;
`;

const StyledTextBubbleWrapper = styled.ul`
  padding: 14px;
  flex-grow: 1;
  background-color: rgba(234, 254, 254, 0.7);
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;

  & > li {
    .spinner {
      display: none;
    }
  }
  & > li:last-child {
    .spinner {
      display: inline-block;
    }
  }
`;

const StyledInputForm = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  background-color: #fff;
  border-top: 1px solid #45d6df;
  z-index: 9;
`;

const StyledTextBubbleSpacer = styled.li`
  margin-top: auto;
`;

const StyledTextButtonWrapper = styled.div`
  margin: 0 auto;
`;

const StyledSpinnerWrapper = styled.div`
  margin: 0 auto;
`;
export {
  StyledChatWrapper,
  StyledHistoryFetchTrigger,
  StyledTextBubbleWrapper,
  StyledInputForm,
  StyledTextBubbleSpacer,
  StyledTextButtonWrapper,
  StyledSpinnerWrapper,
};
