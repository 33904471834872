import { useEffect, useRef, useState } from 'react';
import { useRoomContext } from '@livekit/components-react';
import { DataPacket_Kind, RoomEvent } from 'livekit-client';
import ChatGroup, { ChatItem } from '../../blocks/ChatGroup';
import {
  ChatbotHistoryConnectionDirectionType,
  ChatbotHistoryConnectionInput,
  ChatbotHistoryConnectionOrderFieldType,
  ChatbotHistoryConnectionQuery,
  ChatbotLanguage,
  useChatbotHistoryConnectionQuery,
  useJoinRoomChatbotMutation,
} from '../../../services/generated/graphql';
import getUUID from '../../../util/getUUID';
import formatDateTime from '../../../util/formatDateTime';
import getScrollPositionFromBottom from '../../../util/getScrollPositionFromBottom';
import { customScrollTo } from '../../../util/scrollControl';
import { CHATBOT_IDENTITY } from '../../../constant';
import { useLogFirebaseEvent } from '../../provider/firebase/FirebaseProvider';

interface ChatTemplateProps {
  roomName: string;
  name: string;
}

function ChatTemplate({ roomName, name }: ChatTemplateProps) {
  const [chatLog, setChatLog] = useState<ChatItem[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isChatbotLeave, setIsChatbotLeave] = useState(false);
  const [isFinal, setIsFinal] = useState<boolean>(false);
  const [isHistoryFetch, setIsHistoryFetch] = useState<boolean>(false);
  const [cursor, setCursor] = useState<string>('');
  const [firstHistoryLoaded, setFirstHistoryLoaded] = useState<boolean>(true);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const prevLastItemId = useRef<string | null>(null);
  const encoder = new TextEncoder();
  const decoder = new TextDecoder();
  const room = useRoomContext();
  const [joinRoomChatbotMutation] = useJoinRoomChatbotMutation();

  const {
    logViewChatPageEvent,
    logHistoryFetchEvent,
    logHistoryFetchErrorEvent,
    logJoinRoomChatbotEvent,
    logJoinRoomChatbotErrorEvent,
  } = useLogFirebaseEvent();

  function connectedCallbackOnCompleted() {
    setIsHistoryFetch(true);
    setIsDisabled(false);
    logJoinRoomChatbotEvent();
  }

  const onConnectedCallback = async () => {
    await joinRoomChatbotMutation({
      variables: {
        input: {
          roomName,
          gptOption: { langauge: ChatbotLanguage.Ko },
        },
      },
      onCompleted: connectedCallbackOnCompleted,
      onError: (error) => {
        console.log(error);
        logJoinRoomChatbotErrorEvent();
      },
    });
  };

  function saveSendMessage(text: string) {
    if (text.length === 0) return;

    setChatLog((prev) => [
      ...prev,
      {
        id: getUUID(),
        text,
        timeStamp: formatDateTime(new Date()),
        direction: 'right',
      },
    ]);
  }

  function createEncodedMessage(text: string) {
    const json = JSON.stringify({
      sid: room.sid,
      roomName,
      name: room.localParticipant.identity,
      text,
      time: new Date().toISOString(),
      isGPT: false,
    });
    return encoder.encode(json);
  }

  function publishMessage(encodedData: Uint8Array) {
    if (room.state === 'connected') {
      room.localParticipant.publishData(
        encodedData as Uint8Array,
        DataPacket_Kind.RELIABLE
      );
    }
  }

  const onSendMessage = (text: string) => {
    if (!text) return;
    saveSendMessage(text);
    setIsDisabled(true);
    publishMessage(createEncodedMessage(text));
  };

  function saveReceivedMessage(packet: any) {
    if (!packet) return;
    const isGPT = packet.data && packet.data.isGPT === true;
    if (isGPT) {
      setChatLog((prev) => {
        const isFirstPayload = prev[prev.length - 1].direction === 'right';
        if (isFirstPayload || packet.data.isFinal) {
          return [
            ...prev,
            {
              id: getUUID(),
              text: packet.data.text,
              imageUrl: packet.data.imageUrl,
              linkUrl: packet.data.linkUrl,
              timeStamp: formatDateTime(packet.data.time),
              direction: 'left',
            },
          ];
        }

        return prev.map((item, idx) => {
          const isRemainingPayloads =
            idx === prev.length - 1 && !packet.data.isFinal;
          if (isRemainingPayloads) {
            return {
              ...item,
              text: item.text + packet.data.text,
            };
          }
          return item;
        });
      });
      setIsFinal(!packet.data.isFinal);
      setIsDisabled(!packet.data.isFinal);
    }

    const isUser = packet && packet.isGPT === false;
    if (isUser) {
      setChatLog((prev) => [
        ...prev,
        {
          id: getUUID(),
          text: packet.text,
          timeStamp: formatDateTime(packet.time),
          direction: 'right',
        },
      ]);
    }
  }

  function onReceivedMessage(payload: Uint8Array) {
    const packet = JSON.parse(decoder.decode(payload));
    console.log(packet);
    saveReceivedMessage(packet);
    customScrollTo('bottom');
  }

  function participantConnected(participant: any) {
    if (participant.identity === CHATBOT_IDENTITY) {
      setIsChatbotLeave(false);
    }
  }

  function participantDisconnected(participant: any) {
    if (participant.identity === CHATBOT_IDENTITY) {
      setIsChatbotLeave(true);
      setLoading(false);
      if (!participant) setIsDisabled(true);
    }
  }

  function saveCursor(historyList: ChatbotHistoryConnectionQuery) {
    const { pageInfo } = historyList.chatbotHistoryConnection;
    if (!pageInfo || pageInfo.endCursor === '') return;
    setCursor(pageInfo.endCursor);
  }

  let scrollTimeoutId: NodeJS.Timeout;
  let toBottomTimeoutId: NodeJS.Timeout;

  function unshiftHistoryToChatLog(combinedHistoryList: ChatItem[]) {
    setChatLog((prev) => [...combinedHistoryList, ...prev]);

    scrollTimeoutId = setTimeout(() => {
      const fetchBeforeScrollPosition = scrollPosition;
      const targetPosition =
        document.body.clientHeight - fetchBeforeScrollPosition;
      customScrollTo('bottom', targetPosition);
    }, 0);

    if (firstHistoryLoaded) {
      toBottomTimeoutId = setTimeout(() => {
        setFirstHistoryLoaded(false);
      }, 0);
    }
  }

  function convertHistoryToChatList(
    historyList: ChatbotHistoryConnectionQuery
  ) {
    const { nodes } = historyList.chatbotHistoryConnection;
    const reverseNodes = [...nodes].reverse();
    const hasNoMoreData = nodes.length === 0;
    if (!reverseNodes || hasNoMoreData) return;

    const combinedHistoryList: ChatItem[] = [];

    for (const node of reverseNodes) {
      const gptAnswer: ChatItem = {
        id: getUUID(),
        text: node.gptAnswer,
        imageUrl: node.imageUrl,
        linkUrl: node.linkUrl,
        timeStamp: formatDateTime(new Date(node.answerTime)),
        direction: 'left',
      };

      const userQuestion: ChatItem = {
        id: getUUID(),
        text: node.userQuestion,
        timeStamp: formatDateTime(new Date(node.createdAt)),
        direction: 'right',
      };

      combinedHistoryList.push(userQuestion, gptAnswer);
    }

    unshiftHistoryToChatLog(combinedHistoryList);
  }

  function chatbotHistoryOnCompleted(
    historyData: ChatbotHistoryConnectionQuery | undefined
  ) {
    if (!historyData) return;

    setIsHistoryFetch(false);
    convertHistoryToChatList(historyData);
    saveCursor(historyData);
    setLoading(false);
    logHistoryFetchEvent();
  }

  const historyRequestData: ChatbotHistoryConnectionInput = {
    first: 4,
    where: { roomName, name },
    orderby: {
      direction: ChatbotHistoryConnectionDirectionType.Desc,
      field: ChatbotHistoryConnectionOrderFieldType.Id,
    },
    ...(cursor && { after: cursor }),
  };

  const { data: historyData } = useChatbotHistoryConnectionQuery({
    variables: {
      input: historyRequestData,
    },
    skip: isHistoryFetch === false,
    onCompleted: () => chatbotHistoryOnCompleted(historyData),
    onError: (error) => {
      console.log(error);
      logHistoryFetchErrorEvent();
    },
  });

  function intersectionObserverCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry: { isIntersecting: boolean }) => {
      if (entry.isIntersecting && room.state === 'connected') {
        setIsHistoryFetch(true);
        setScrollPosition(getScrollPositionFromBottom());
      }
    });
  }

  function setUpIntersectionObserver() {
    const triggerElement = document.getElementById('historyFetchTrigger');
    const options = { threshold: 1 };
    const observer = new IntersectionObserver(
      intersectionObserverCallback,
      options
    );

    if (triggerElement) observer.observe(triggerElement);

    return observer;
  }

  useEffect(() => {
    const currentLastItem = chatLog[chatLog.length - 1];
    if (currentLastItem && currentLastItem.id !== prevLastItemId.current) {
      customScrollTo('bottom');
      prevLastItemId.current = currentLastItem.id;
    }
  }, [chatLog]);

  useEffect(() => {
    if (!room) return;
    logViewChatPageEvent();
    room.on(RoomEvent.Connected, onConnectedCallback);
    room.on(RoomEvent.DataReceived, onReceivedMessage);
    room.on(RoomEvent.ParticipantDisconnected, participantDisconnected);
    room.on(RoomEvent.ParticipantConnected, participantConnected);
    const observer = setUpIntersectionObserver();

    return () => {
      room.off(RoomEvent.Connected, onConnectedCallback);
      room.off(RoomEvent.DataReceived, onReceivedMessage);
      room.off(RoomEvent.ParticipantDisconnected, participantDisconnected);
      room.off(RoomEvent.ParticipantConnected, participantConnected);
      observer.disconnect();
      clearTimeout(toBottomTimeoutId);
      clearTimeout(scrollTimeoutId);
    };
  }, [room]);

  return (
    <ChatGroup
      isLoading={loading}
      reCallChatbot={onConnectedCallback}
      isFinal={isFinal}
      isChatbotLeave={isChatbotLeave}
      onSubmit={onSendMessage}
      chatList={chatLog}
      isDisabled={isDisabled}
    />
  );
}
export default ChatTemplate;
