import styled from '@emotion/styled';
import Input from '../../atoms/Input';

const StyledEmailInputForm = styled.form`
  padding: 48px;
  background-color: #fff;
  height: 100vh;
`;

const StyledTitle = styled.h2`
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;

const StyledInput = styled(Input)`
  margin-bottom: 12px;
`;

const StyledMarketingBox = styled.div`
  padding: 20px 0;

  & > input[type='checkbox'] {
    margin-right: 4px;
  }
`;

const StyledTermText = styled.p`
  padding-top: 12px;
`;

export {
  StyledTitle,
  StyledEmailInputForm,
  StyledInput,
  StyledMarketingBox,
  StyledTermText,
};
