import { FormEvent } from 'react';
import EmailInputForm from '../../blocks/EmailInputForm';
import validateEmail from '../../../util/validateEmail';

function EmailTemplate() {
  function redirectChat(email: string) {
    window.location.href = `/chat/?roomName=${email}&identity=${email}`;
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const email = new FormData(e.target as HTMLFormElement).get(
      'email'
    ) as string;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (validateEmail(email, emailRegex)) {
      redirectChat(email);
      e.currentTarget.email.value = '';
    } else {
      alert('Invalid email format. Please enter it again.');
    }
  }

  return <EmailInputForm onSubmit={(e) => onSubmit(e)} name="email" />;
}
export default EmailTemplate;
