import { LiveKitRoom } from '@livekit/components-react';
import { useState } from 'react';
import {
  ChatbotJoinTokenQuery,
  useChatbotJoinTokenQuery,
} from '../../services/generated/graphql';
import ChatTemplate from '../../components/templates/ChatTemplate';
import getUUID from '../../util/getUUID';
import { Container } from './styled';
import TokenError from '../../components/atoms/Errors/TokenError';
import { LIVE_KIT_SERVER } from '../../constant';
import { useLogFirebaseEvent } from '../../components/provider/firebase/FirebaseProvider';

function Chat() {
  const [token, setToken] = useState<string>();
  const roomName: string =
    new URLSearchParams(window.location.search).get('roomName') || getUUID();
  const identity: string =
    new URLSearchParams(window.location.search).get('identity') || getUUID();

  const { logJoinTokenEvent, logJoinTokenErrorEvent } = useLogFirebaseEvent();

  const { error } = useChatbotJoinTokenQuery({
    variables: { input: { roomName, identity } },
    onCompleted: (res: ChatbotJoinTokenQuery) => {
      setToken(res.chatbotJoinToken);
      logJoinTokenEvent();
    },
    onError: logJoinTokenErrorEvent,
  });

  return (
    <Container>
      <LiveKitRoom token={token} serverUrl={LIVE_KIT_SERVER}>
        <ChatTemplate roomName={roomName} name={identity} />
      </LiveKitRoom>

      <TokenError isView={error} />
    </Container>
  );
}
export default Chat;
