import { ButtonHTMLAttributes } from 'react';
import { StyledButton } from './styled';
import { MsgSendIcon } from '../../../../assets/svg';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  bgColor: string;
}

function Button({ bgColor, ...rest }: ButtonProps) {
  return (
    <StyledButton {...rest}>
      <MsgSendIcon bgColor={bgColor} />
    </StyledButton>
  );
}
export default Button;
