import { ButtonHTMLAttributes } from 'react';
import { StyleButton, StyledIconWrapper } from './styled';
import { colors } from '../../../../style/theme';

type TextButtonProps = {
  contentFit?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  colorScheme?: 'primary' | 'white';
  color?: keyof typeof colors;
} & ButtonHTMLAttributes<HTMLButtonElement>;

function TextButton({
  contentFit,
  leftIcon,
  rightIcon,
  colorScheme = 'primary',
  children,
  color = 'primary',
  ...props
}: TextButtonProps) {
  return (
    <StyleButton
      contentFit={contentFit}
      colorScheme={colorScheme}
      color={color}
      {...props}
    >
      {leftIcon && (
        <StyledIconWrapper direction="left">{leftIcon}</StyledIconWrapper>
      )}

      {children}

      {rightIcon && (
        <StyledIconWrapper direction="right">{rightIcon}</StyledIconWrapper>
      )}
    </StyleButton>
  );
}
export default TextButton;
