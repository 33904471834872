import styled from '@emotion/styled';

const Container = styled.div`
  position: absolute;
  background: rgba(182, 182, 182, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
`;

export { Container, ContentWrapper, Title };
