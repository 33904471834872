function formatDateTime(timeStamp: Date) {
  const originalDate = new Date(timeStamp);
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
  const date = originalDate.getDate().toString().padStart(2, '0');
  const hours = originalDate.getHours().toString().padStart(2, '0');
  const minutes = originalDate.getMinutes().toString().padStart(2, '0');

  const compiledTime = `${year}/${month}/${date} ${hours}:${minutes}`;
  return compiledTime;
}
export default formatDateTime;
