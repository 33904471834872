import { HTMLAttributes } from 'react';
import {
  StyledBubbleDescription,
  StyledImage,
  StyledLinkBubble,
  StyledLinkText,
} from './styled';
import { StyledLeftTimeStamp } from '../TextBubble/styled';

export interface TextBubbleProps extends HTMLAttributes<HTMLAnchorElement> {
  timeStamp: string;
  linkUrl: string;
  imageUrl: string;
}

function LinkBubble({
  children,
  timeStamp,
  linkUrl,
  imageUrl,
  ...rest
}: TextBubbleProps) {
  return (
    <li>
      <StyledLinkBubble href={linkUrl} target="_blank" {...rest}>
        <StyledImage src={imageUrl} alt="result" />
        <StyledBubbleDescription>Order Page</StyledBubbleDescription>
        <StyledLinkText>{children}</StyledLinkText>
      </StyledLinkBubble>
      <StyledLeftTimeStamp>{timeStamp || ''}</StyledLeftTimeStamp>
    </li>
  );
}
export default LinkBubble;
