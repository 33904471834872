import styled from '@emotion/styled';

const StyledLeftBubble = styled.p`
  position: relative;
  padding: 12px;
  margin-left: 12px;
  width: fit-content;
  max-width: 75%;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: solid 1px #1b3852;
  background-color: #fff;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  white-space: pre-wrap;

  &::after {
    border-color: #fff transparent;
    border-style: solid;
    border-width: 0 0px 12px 12px;
    content: '';
    display: block;
    left: -9.7px;
    position: absolute;
    top: 0;
    width: 0;
    z-index: 1;
    transform: rotate(270deg);
  }

  &::before {
    border-color: #1b3852 transparent;
    border-style: solid;
    border-width: 0 0px 12px 12px;
    content: '';
    display: block;
    left: -12px;
    position: absolute;
    top: -1px;
    width: 0;
    z-index: 0;
    transform: rotate(270deg);
  }
`;

const StyledRightBubble = styled.p`
  position: relative;
  padding: 12px;
  margin-left: auto;
  margin-right: 12px;
  width: fit-content;
  max-width: 75%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: solid 1px #45d6df;
  background-color: #fff;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  white-space: pre-wrap;

  &::after {
    border-color: #fff transparent;
    border-style: solid;
    border-width: 12px 12px 0px 12px;
    content: '';
    display: block;
    right: -10.5px;
    position: absolute;
    top: 0;
    width: 0;
    z-index: 1;
  }

  &::before {
    border-color: #45d6df transparent;
    border-style: solid;
    border-width: 12px 12px 0px 12px;
    content: '';
    display: block;
    right: -13px;
    position: absolute;
    top: -1px;
    width: 0;
    z-index: 0;
  }
`;

const StyledFlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLeftTimeStamp = styled.p`
  display: inline-block;
  margin-bottom: 20px;
  padding: 4px 0 0 12px;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #333;
`;

const StyledRightTimeStamp = styled.p`
  margin-bottom: 20px;
  padding: 4px 12px 0 0;
  text-align: right;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #333;
`;

export {
  StyledLeftBubble,
  StyledRightBubble,
  StyledLeftTimeStamp,
  StyledRightTimeStamp,
  StyledFlexBox,
};
