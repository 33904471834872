import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBG1HSyvqutLHC-TkuqH3tvq2yeTs2_z70',
  authDomain: 'chatbot-e56dd.firebaseapp.com',
  projectId: 'chatbot-e56dd',
  storageBucket: 'chatbot-e56dd.appspot.com',
  messagingSenderId: '341356377761',
  appId: '1:341356377761:web:44abb3b308785a3ee062e0',
  measurementId: 'G-8J5V14T01G',
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
