import styled from '@emotion/styled';

const StyledInput = styled.input`
  padding: 4px 12px;
  height: 40px;
  width: 100%;
  border: solid 1px #1b3852;
  border-radius: 5px;
  background-color: #fff;
  outline: none;
  font-size: 20px;

  &:focus {
    border-color: #45d6df;
  }
`;

export { StyledInput };
