import { FormEvent } from 'react';
import { StyledForm } from './styled';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button/SendButton';

interface InputFormProps {
  onSubmit: (text: string) => void;
  disabled?: boolean;
}

function ChatInputForm({ onSubmit, disabled }: InputFormProps) {
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const submitData = new FormData(e.currentTarget);
    const text = submitData.get('text');

    if (text === null || (text as string).trim() === '') return;
    onSubmit(text as string);

    e.currentTarget.text.value = '';
  }

  return (
    <StyledForm onSubmit={(e) => handleSubmit(e)}>
      <Input
        disabled={disabled}
        isFocus
        type="text"
        name="text"
        placeholder="Please write the product specifications."
      />
      <Button
        type="submit"
        disabled={disabled}
        bgColor={disabled ? '#EEEEEE' : '#45D6DF'}
      />
    </StyledForm>
  );
}
export default ChatInputForm;
