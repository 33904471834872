/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable prettier/prettier */
/* eslint-disable import/newline-after-import */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _Any: any;
  _FieldSet: any;
};

/** GPT 설정 옵션 입력 */
export type ChatbotGptOptionInput = {
  langauge?: InputMaybe<ChatbotLanguage>;
  maxTokens?: InputMaybe<Scalars['Int']>;
  temperature?: InputMaybe<Scalars['Float']>;
};

/** 메세지 */
export type ChatbotHistory = {
  __typename?: 'ChatbotHistory';
  /** 시간 time RFC3339 */
  answerTime: Scalars['String'];
  /** 등록 시간 */
  createdAt: Scalars['String'];
  /** 에러 메세지 */
  errorMessage: Scalars['String'];
  /** 답변 */
  gptAnswer: Scalars['String'];
  /** DB ID */
  id: Scalars['ID'];
  /** 이미지   */
  imageUrl: Scalars['String'];
  /** 답변 여부 */
  isGptAnswer: Scalars['Boolean'];
  /** 링크 */
  linkUrl: Scalars['String'];
  /** roomName 룸 Name */
  roomName: Scalars['String'];
  /** 유저 이름 */
  userName: Scalars['String'];
  /** 유저 질문 */
  userQuestion: Scalars['String'];
};

/** 순서 방향 */
export enum ChatbotHistoryConnectionDirectionType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ChatbotHistoryConnectionInput = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderby?: InputMaybe<ChatbotHistoryConnectionOrder>;
  where?: InputMaybe<ChatbotHistoryConnectionWhere>;
};

/** 순서 */
export type ChatbotHistoryConnectionOrder = {
  direction?: InputMaybe<ChatbotHistoryConnectionDirectionType>;
  field?: InputMaybe<ChatbotHistoryConnectionOrderFieldType>;
};

/** 순서 필드 정의 */
export enum ChatbotHistoryConnectionOrderFieldType {
  CreatedAt = 'CREATED_AT',
  Id = 'ID',
}

export type ChatbotHistoryConnectionOutput = {
  __typename?: 'ChatbotHistoryConnectionOutput';
  nodes: Array<ChatbotHistory>;
  /** 페이지 정보 */
  pageInfo: PageInfo;
  /** 총수 */
  totalCount: Scalars['Int'];
};

/** 조회 */
export type ChatbotHistoryConnectionWhere = {
  /** 이름 */
  name?: InputMaybe<Scalars['String']>;
  /** 룸이름 */
  roomName?: InputMaybe<Scalars['String']>;
};

/** 참여 room 대한 토큰 생성 입력 */
export type ChatbotJoinTokenInput = {
  /** 참여자 이름 */
  identity: Scalars['String'];
  /** 룸이름 */
  roomName: Scalars['String'];
};

/** GPT는 자동언어로 분석하지만, 추후 stt, tts를 위한다면 설정은 필요함. */
export enum ChatbotLanguage {
  En = 'EN',
  Es = 'ES',
  Ko = 'KO',
}

/** 챗봇 데이타 패킷 */
export type ChatbotPacket = {
  __typename?: 'ChatbotPacket';
  /** 패킷 데이타 */
  data?: Maybe<ChatbotPacketMessage>;
  /** 에러 */
  error?: Maybe<Scalars['String']>;
  /** 상태 */
  state?: Maybe<ChatbotState>;
  /** 패킷 타입 */
  type: ChatbotPacketType;
};

/** 챗봇 전송 메세지 패킷 타입 */
export type ChatbotPacketMessage = {
  __typename?: 'ChatbotPacketMessage';
  /** 이미지   */
  imageUrl: Scalars['String'];
  /** 메세지 마지막 여부 */
  isFinal: Scalars['Boolean'];
  /** GPT 여부 */
  isGPT: Scalars['Boolean'];
  /** 링크 */
  linkUrl: Scalars['String'];
  /** 이름 */
  name: Scalars['String'];
  /** roomName 룸이름 */
  roomName: Scalars['String'];
  /** 메세지 */
  text: Scalars['String'];
  /** 시간 time RFC3339 */
  time: Scalars['String'];
};

/** chatbot 메세지 패킷 타입 */
export enum ChatbotPacketType {
  /** 에러 타입 */
  Error = 'ERROR',
  /** 상태 태입 */
  State = 'STATE',
  /** 메세지 타입 */
  Transcript = 'TRANSCRIPT',
}

/** 챗봇 GPT 상태 */
export enum ChatbotState {
  /** 진행중 */
  Active = 'ACTIVE',
  /** 대기 */
  Idle = 'IDLE',
}

export type JoinRoomChatbotInput = {
  /** gpt 설정 옵션 */
  gptOption?: InputMaybe<ChatbotGptOptionInput>;
  /** 룸이름 */
  roomName: Scalars['String'];
};

/** 챗봇 등록 결과 */
export type JoinRoomChatbotOutput = {
  __typename?: 'JoinRoomChatbotOutput';
  success: Scalars['Boolean'];
};

/** 챗봇 종료 입력 */
export type LeaveChatbotInput = {
  /** 룸이름 */
  roomName: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** 챗봇용 방 참가 */
  joinRoomChatbot?: Maybe<JoinRoomChatbotOutput>;
  /** 챗봇 종료 */
  leaveChatbot: Scalars['Boolean'];
};

export type MutationJoinRoomChatbotArgs = {
  input: JoinRoomChatbotInput;
};

export type MutationLeaveChatbotArgs = {
  input: LeaveChatbotInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String'];
  hasNextPage: Scalars['Boolean'];
  startCursor: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  /** 채팅 히스토리 요청 */
  chatbotHistoryConnection: ChatbotHistoryConnectionOutput;
  /** 채팅 참여 토큰 발행 */
  chatbotJoinToken: Scalars['String'];
};

export type QueryChatbotHistoryConnectionArgs = {
  input: ChatbotHistoryConnectionInput;
};

export type QueryChatbotJoinTokenArgs = {
  input: ChatbotJoinTokenInput;
};

/** 챗봇 발송 메세지 */
export type SendToChatbotPacket = {
  __typename?: 'SendToChatbotPacket';
  /** 이름 */
  name: Scalars['String'];
  /** roomName 룸이름 */
  roomName: Scalars['String'];
  /** 메세지 */
  text: Scalars['String'];
};

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type ChatbotJoinTokenQueryVariables = Exact<{
  input: ChatbotJoinTokenInput;
}>;

export type ChatbotJoinTokenQuery = {
  __typename?: 'Query';
  chatbotJoinToken: string;
};

export type JoinRoomChatbotMutationVariables = Exact<{
  input: JoinRoomChatbotInput;
}>;

export type JoinRoomChatbotMutation = {
  __typename?: 'Mutation';
  joinRoomChatbot?:
    | { __typename?: 'JoinRoomChatbotOutput'; success: boolean }
    | null
    | undefined;
};

export type LeaveChatbotMutationVariables = Exact<{
  input: LeaveChatbotInput;
}>;

export type LeaveChatbotMutation = {
  __typename?: 'Mutation';
  leaveChatbot: boolean;
};

export type ChatbotHistoryConnectionQueryVariables = Exact<{
  input: ChatbotHistoryConnectionInput;
}>;

export type ChatbotHistoryConnectionQuery = {
  __typename?: 'Query';
  chatbotHistoryConnection: {
    __typename?: 'ChatbotHistoryConnectionOutput';
    totalCount: number;
    nodes: Array<{
      __typename?: 'ChatbotHistory';
      id: string;
      roomName: string;
      userName: string;
      userQuestion: string;
      gptAnswer: string;
      imageUrl: string;
      linkUrl: string;
      isGptAnswer: boolean;
      errorMessage: string;
      answerTime: string;
      createdAt: string;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string;
      startCursor: string;
      hasNextPage: boolean;
    };
  };
};

export const ChatbotJoinTokenDocument = gql`
  query chatbotJoinToken($input: ChatbotJoinTokenInput!) {
    chatbotJoinToken(input: $input)
  }
`;

/**
 * __useChatbotJoinTokenQuery__
 *
 * To run a query within a React component, call `useChatbotJoinTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatbotJoinTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatbotJoinTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatbotJoinTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatbotJoinTokenQuery,
    ChatbotJoinTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatbotJoinTokenQuery, ChatbotJoinTokenQueryVariables>(
    ChatbotJoinTokenDocument,
    options
  );
}
export function useChatbotJoinTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatbotJoinTokenQuery,
    ChatbotJoinTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChatbotJoinTokenQuery,
    ChatbotJoinTokenQueryVariables
  >(ChatbotJoinTokenDocument, options);
}
export type ChatbotJoinTokenQueryHookResult = ReturnType<
  typeof useChatbotJoinTokenQuery
>;
export type ChatbotJoinTokenLazyQueryHookResult = ReturnType<
  typeof useChatbotJoinTokenLazyQuery
>;
export type ChatbotJoinTokenQueryResult = Apollo.QueryResult<
  ChatbotJoinTokenQuery,
  ChatbotJoinTokenQueryVariables
>;
export const JoinRoomChatbotDocument = gql`
  mutation joinRoomChatbot($input: JoinRoomChatbotInput!) {
    joinRoomChatbot(input: $input) {
      success
    }
  }
`;
export type JoinRoomChatbotMutationFn = Apollo.MutationFunction<
  JoinRoomChatbotMutation,
  JoinRoomChatbotMutationVariables
>;

/**
 * __useJoinRoomChatbotMutation__
 *
 * To run a mutation, you first call `useJoinRoomChatbotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinRoomChatbotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinRoomChatbotMutation, { data, loading, error }] = useJoinRoomChatbotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinRoomChatbotMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinRoomChatbotMutation,
    JoinRoomChatbotMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinRoomChatbotMutation,
    JoinRoomChatbotMutationVariables
  >(JoinRoomChatbotDocument, options);
}
export type JoinRoomChatbotMutationHookResult = ReturnType<
  typeof useJoinRoomChatbotMutation
>;
export type JoinRoomChatbotMutationResult =
  Apollo.MutationResult<JoinRoomChatbotMutation>;
export type JoinRoomChatbotMutationOptions = Apollo.BaseMutationOptions<
  JoinRoomChatbotMutation,
  JoinRoomChatbotMutationVariables
>;
export const LeaveChatbotDocument = gql`
  mutation leaveChatbot($input: LeaveChatbotInput!) {
    leaveChatbot(input: $input)
  }
`;
export type LeaveChatbotMutationFn = Apollo.MutationFunction<
  LeaveChatbotMutation,
  LeaveChatbotMutationVariables
>;

/**
 * __useLeaveChatbotMutation__
 *
 * To run a mutation, you first call `useLeaveChatbotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveChatbotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveChatbotMutation, { data, loading, error }] = useLeaveChatbotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLeaveChatbotMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveChatbotMutation,
    LeaveChatbotMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveChatbotMutation,
    LeaveChatbotMutationVariables
  >(LeaveChatbotDocument, options);
}
export type LeaveChatbotMutationHookResult = ReturnType<
  typeof useLeaveChatbotMutation
>;
export type LeaveChatbotMutationResult =
  Apollo.MutationResult<LeaveChatbotMutation>;
export type LeaveChatbotMutationOptions = Apollo.BaseMutationOptions<
  LeaveChatbotMutation,
  LeaveChatbotMutationVariables
>;
export const ChatbotHistoryConnectionDocument = gql`
  query chatbotHistoryConnection($input: ChatbotHistoryConnectionInput!) {
    chatbotHistoryConnection(input: $input) {
      nodes {
        id
        roomName
        userName
        userQuestion
        gptAnswer
        imageUrl
        linkUrl
        isGptAnswer
        errorMessage
        answerTime
        createdAt
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
`;

/**
 * __useChatbotHistoryConnectionQuery__
 *
 * To run a query within a React component, call `useChatbotHistoryConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatbotHistoryConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatbotHistoryConnectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatbotHistoryConnectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatbotHistoryConnectionQuery,
    ChatbotHistoryConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChatbotHistoryConnectionQuery,
    ChatbotHistoryConnectionQueryVariables
  >(ChatbotHistoryConnectionDocument, options);
}
export function useChatbotHistoryConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatbotHistoryConnectionQuery,
    ChatbotHistoryConnectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChatbotHistoryConnectionQuery,
    ChatbotHistoryConnectionQueryVariables
  >(ChatbotHistoryConnectionDocument, options);
}
export type ChatbotHistoryConnectionQueryHookResult = ReturnType<
  typeof useChatbotHistoryConnectionQuery
>;
export type ChatbotHistoryConnectionLazyQueryHookResult = ReturnType<
  typeof useChatbotHistoryConnectionLazyQuery
>;
export type ChatbotHistoryConnectionQueryResult = Apollo.QueryResult<
  ChatbotHistoryConnectionQuery,
  ChatbotHistoryConnectionQueryVariables
>;
