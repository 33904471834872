import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '../../../../style/theme';

const StyleButton = styled.button<{
  contentFit?: boolean;
  colorScheme: keyof typeof colors;
  color: keyof typeof colors;
}>`
  ${({ contentFit, colorScheme, color }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    padding: 14px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18);
    cursor: pointer;
    color: ${colors[color][100]};
    width: ${contentFit ? 'fit-content' : '100%'};
    background-color: ${colors[colorScheme][100]};

    &:disabled {
      background-color: #dcdcdc;
    }
  `}
`;

const StyledIconWrapper = styled.div<{ direction: 'left' | 'right' }>`
  ${({ direction }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: ${direction === 'left' ? '0 10px 0 0' : '0 0 0 10px'};
  `}
`;

export { StyleButton, StyledIconWrapper };
