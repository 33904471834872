import { Spinner } from '@chakra-ui/react';
import { HTMLAttributes } from 'react';
import {
  StyledLeftBubble,
  StyledRightBubble,
  StyledRightTimeStamp,
  StyledLeftTimeStamp,
} from './styled';

export interface TextBubbleProps extends HTMLAttributes<HTMLParagraphElement> {
  timeStamp: string;
  direction: 'left' | 'right';
  isFinal: boolean;
}

function TextBubble({
  children,
  direction,
  timeStamp,
  isFinal,
  ...rest
}: TextBubbleProps) {
  switch (direction) {
    case 'left':
      return (
        <li>
          <StyledLeftBubble {...rest}>{children}</StyledLeftBubble>
          <StyledLeftTimeStamp>{timeStamp || ''}</StyledLeftTimeStamp>
          {isFinal && (
            <Spinner
              className="spinner"
              color="#45d6df"
              size="xs"
              thickness="3px"
              marginLeft="10px"
              marginBottom="-1px"
            />
          )}
        </li>
      );
    case 'right':
      return (
        <li>
          <StyledRightBubble {...rest}>{children}</StyledRightBubble>
          <StyledRightTimeStamp>{timeStamp || ''}</StyledRightTimeStamp>
        </li>
      );
    default:
      return null;
  }
}
export default TextBubble;
