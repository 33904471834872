import { FormEvent, useState } from 'react';
import TextButton from '../../atoms/Button/TextButton';
import {
  StyledEmailInputForm,
  StyledInput,
  StyledMarketingBox,
  StyledTermText,
  StyledTitle,
} from './styled';

type EmailInputFormProps = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  name?: string;
};

function EmailInputForm({ onSubmit, name }: EmailInputFormProps) {
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <StyledEmailInputForm onSubmit={(e) => onSubmit(e)}>
      <StyledTitle>Order with MODI Factory AI</StyledTitle>
      <StyledInput placeholder="Enter your Email" isFocus name={name} />
      <StyledMarketingBox>
        <input
          id="marketing"
          type="checkbox"
          value={isChecked ? 'true' : 'false'}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="marketing">
          I agree to receive email marketing information.
        </label>
      </StyledMarketingBox>
      <TextButton disabled={!isChecked} type="submit" color="white">
        Start chat
      </TextButton>
      <StyledTermText>
        By creating an account, you agree to Luxrobo’s Terms of Service and
        Privacy Policy.
      </StyledTermText>
    </StyledEmailInputForm>
  );
}
export default EmailInputForm;
