export const colors = {
  primary: {
    100: '#45d6df',
  },
  white: {
    100: '#ffffff',
  },
  black: {
    100: '#000000',
  },
} as const;
