import { InputHTMLAttributes, useEffect, useRef } from 'react';
import { StyledInput } from './styled';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  isFocus?: boolean;
}

function Input({ isFocus, type, placeholder, disabled, ...rest }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocus && inputRef.current && !disabled) inputRef.current.focus();
  }, [disabled]);

  return (
    <StyledInput
      ref={inputRef}
      type={type || 'text'}
      placeholder={placeholder || ''}
      {...rest}
    />
  );
}
export default Input;
