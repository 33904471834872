/* eslint-disable no-shadow */
import { ReactNode, createContext, useContext } from 'react';
import { Analytics, logEvent } from 'firebase/analytics';
import { analytics } from './firebaseConfig';

type FirebaseProviderProps = {
  children: ReactNode;
};

const FirebaseContext = createContext<Analytics | null>(null);

function FirebaseProvider({ children }: FirebaseProviderProps) {
  return (
    <FirebaseContext.Provider value={analytics}>
      {children}
    </FirebaseContext.Provider>
  );
}
export default FirebaseProvider;

function useFirebase() {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
}

export function useLogFirebaseEvent() {
  const analytics = useFirebase();

  // 페이지 뷰 관련 로깅 함수
  const logViewChatPageEvent = () => {
    logEvent(analytics as Analytics, 'view_chat_page');
  };

  // 내부 api 관련 로깅 함수
  const logHistoryFetchEvent = () => {
    logEvent(analytics as Analytics, 'history_Connection');
  };

  const logHistoryFetchErrorEvent = () => {
    logEvent(analytics as Analytics, 'history_Connection_error');
  };

  const logJoinTokenEvent = () => {
    logEvent(analytics as Analytics, 'use_chatbot_join_token_query');
  };

  const logJoinTokenErrorEvent = () => {
    logEvent(analytics as Analytics, 'use_chatbot_join_token_query_error');
  };

  const logJoinRoomChatbotEvent = () => {
    logEvent(analytics as Analytics, 'join_room_chatbot_mutation');
  };

  const logJoinRoomChatbotErrorEvent = () => {
    logEvent(analytics as Analytics, 'join_room_chatbot_mutation_error');
  };

  return {
    logViewChatPageEvent,
    logHistoryFetchEvent,
    logHistoryFetchErrorEvent,
    logJoinTokenEvent,
    logJoinTokenErrorEvent,
    logJoinRoomChatbotEvent,
    logJoinRoomChatbotErrorEvent,
  };
}
