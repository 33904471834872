import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { reset } from './style/reset';
import Email from './pages/Email';
import Chat from './pages/Chat';
import { APOLLO_CLIENT_URI } from './constant';
import FirebaseProvider from './components/provider/firebase/FirebaseProvider';

function App() {
  const client = new ApolloClient({
    uri: APOLLO_CLIENT_URI,
    cache: new InMemoryCache(),
  });

  return (
    <>
      <Global styles={reset} />
      <div className="App">
        <FirebaseProvider>
          <ApolloProvider client={client}>
            <ChakraProvider>
              <Router>
                <Routes>
                  <Route path="/" element={<Email />} />
                  <Route path="/chat" element={<Chat />} />
                </Routes>
              </Router>
            </ChakraProvider>
          </ApolloProvider>
        </FirebaseProvider>
      </div>
    </>
  );
}

export default App;
