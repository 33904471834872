// TODO: id말고 dom으로 받아오는 형태로 변경

export function customScrollTo(
  scrollDirection: 'top' | 'bottom',
  customPosition?: number,
  id?: string
) {
  const targetElement = (id && document.getElementById(id)) || document.body;

  switch (scrollDirection) {
    case 'top':
      window.scrollTo({ top: customPosition || 0, behavior: 'smooth' });

      break;
    case 'bottom':
      window.scrollTo(0, customPosition || targetElement.scrollHeight);
      break;
    default:
  }
}
