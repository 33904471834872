/* eslint-disable react/no-unknown-property */

interface MsgSendIconProps {
  bgColor: string;
}

export { ReactComponent as RefreshIcon } from './refresh.svg';

function MsgSendIcon({ bgColor }: MsgSendIconProps) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <rect fill={bgColor} width="40" height="40" rx="5" />
        <path d="M8 8h24v24H8z" />
        <path
          d="M9.153 10.389a.3.3 0 0 1 .393-.162l22.79 9.496a.3.3 0 0 1 0 .554l-22.79 9.496a.3.3 0 0 1-.407-.348l2.147-8.896 10.316-.43a.1.1 0 0 0 0-.199l-10.316-.43-2.147-8.895a.3.3 0 0 1-.004-.126z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export { MsgSendIcon };
