import styled from '@emotion/styled';

const StyledImage = styled.img`
  object-fit: cover;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const StyledLinkBubble = styled.a`
  display: block;
  margin-left: 12px;
  width: fit-content;
  max-width: 300px;
  border-radius: 5px;
  background-color: #fff;
  color: #222;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const StyledBubbleDescription = styled.p`
  padding: 12px 12px 0 12px;
  font-weight: 800;
`;

const StyledLinkText = styled.p`
  padding: 0 12px 12px 12px;
`;

export {
  StyledImage,
  StyledLinkBubble,
  StyledBubbleDescription,
  StyledLinkText,
};
