import { Container, Title, ContentWrapper } from './styled';

interface TokenErrorProps {
  text?: string;
  isView: any;
}

function TokenError({
  isView,
  text = '토큰 조회 실패 했습니다',
}: TokenErrorProps) {
  if (!isView) return null;

  /** TODO : text 수정 필요
   *  나중에 여기에 토큰 조회 재시도하는 버튼 같은거 넣을 수도 있을거 같습니다
   * */

  return (
    <Container>
      <ContentWrapper>
        <Title>{text}</Title>
      </ContentWrapper>
    </Container>
  );
}

export default TokenError;
